import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export enum EventNames {
  Cancel = 'cancel-booking',
}

export class FedopsLogger {
  constructor(private flowApi: ControllerFlowAPI) {}

  interactionStarted(eventName: EventNames) {
    if (
      this.flowApi.experiments.enabled('specs.bookings.MyBookingsWidgetFedops')
    ) {
      this.flowApi.fedops.interactionStarted(eventName.toString());
    }
  }

  interactionEnded(eventName: EventNames) {
    if (
      this.flowApi.experiments.enabled('specs.bookings.MyBookingsWidgetFedops')
    ) {
      this.flowApi.fedops.interactionEnded(eventName.toString());
    }
  }
}
